@import '../../../Assets/Styles/Variables.scss';

$fontBarlow: 'Barlow', sans-serif;

.congregation.landing-page-container {
  & { width: 100%; margin: 0 auto; box-sizing: border-box; background: #464350; }

  .assess-link { font-family: $fontBarlow; font-size: 21px; font-weight: 700; text-decoration: none; width: 100%; background-color: #ED2028; color: #FFF; padding: 15px 35px; max-width: 200px; box-sizing: border-box; border-radius: 6px; text-align: center; }
  .assess-link:hover { background-color: #100F2E; }

  .header-container-wrapper {
    & { max-width: 1440px; margin: 0 auto; color: #FFFFFF; background-size: cover; background-repeat: no-repeat; height: max-content; }

    .header-container {
      & { color: #FFFFFF;  margin: 0 auto; width: 100%; max-width: 90%; height: min-content; display: flex; align-items: center; justify-content: space-between; padding: 0 1.3% 0px; box-sizing: border-box; }
      .logo-left { width: 100%; max-width: 210px;  align-self: flex-start; }
      img { max-width: 210px; width: 100%; height: auto; }
    }
  }

  .content-container {
      // & { width: 95%; max-width: 1440px; margin: 0 auto; box-sizing: border-box; }

      .top-section {
        & { background-image: url(../../../Assets/Images/LandingPage/bg-congregational.png); background-repeat: no-repeat; background-size: cover; width: 100%; height: 100%; margin: auto; padding-bottom: 40px; }
        .contents {
          & { margin: 0 auto; width: 100%; max-width: 85%; height: min-content;  padding: 0 1.3% 0px; font-family: $primaryfont; }
          h1 { font-size: 48px; color: rgba(255, 255, 255, 1); font-family: $primaryfont; font-size: 48px; font-weight: 700; line-height: 57.6px; text-align: left; max-width: 30ch; }
          p { font-family: $primaryfont; font-size: 24px; font-weight: 700; line-height: 49px; text-align: left; }
          ol {  font-size: 24px; font-weight: 400; line-height: 49px; text-align: left; }
          .completion  {
            & { display: flex; align-items: center; gap: 50px; }
            .time-estimate {  display: flex; align-items: center; gap: 10px; font-size: 21px; font-weight: 400; }
          .cta { width: 100%; max-width: 200px; color: #FFFFFF; background: #ED2028; font-size: 21px; font-weight: 700; line-height: 1; letter-spacing: 1px;  text-align: center; padding: 15px 20px; }
          .cta:hover { background-color: #100F2E; transition: 0.3s; color: #fff; }
          }
        
        }
        .para-container { 
          & {  margin: 70px auto; width: 100%; max-width: 85%;  padding: 0 1.3% 0px; }
          p.para { font-family: $primaryfont; font-size: 18px; font-weight: 400; line-height: 20px; text-align: left; width: 100%; }
        }
      }

      .middle-section {
        & { }
        h3 { font-family: $primaryfont; font-size: 36px; font-weight: 700; line-height: 43.2px; text-align: center; color: #FFFFFF; }
        .custom-slider { 
          & { margin: 0px auto;  display: flex; align-items: center; flex-direction: column; }
          img { height:434.27px;  margin: 0px auto; }
          .cta { width: 100%; max-width: 200px; border-radius: 6px; margin: 20px 0px; color: #FFFFFF; background: #ED2028; font-size: 21px; font-weight: 700; line-height: 1; letter-spacing: 1px; text-align: center; padding: 15px 20px; }
          .cta:hover { background-color: #100F2E; transition: 0.3s; color: #fff; }
        }



        .testimonial-container {
          & { box-sizing: border-box; width: 90%; margin: 50px auto ; font-family: $primaryfont; font-size: 24px; line-height: 36px; display: flex; justify-content: center; background-color: #FFFFFF; border-radius: 35px; }
          
          .testimonial { 
            & { display: flex; flex-direction: column; position: relative; color: #ffffff; font-family: $primaryfont; font-size: 24px; line-height: 45px; text-align: center; }
            .testi { max-width: 100%; box-sizing: border-box; text-align: center; color: #FFFFFF; font-family: $primaryfont; font-size: 19px; font-weight: 400; line-height: 1.2em; }
            .avatar {
              img { border-radius: 50%; padding: 10px; width: auto; height: 150px; }
            }
        
            p { & { font-family: $primaryfont; font-style: normal; font-weight: 400; font-size: 28px; line-height: 44px; max-width: 55ch; color: #343333; margin-bottom: 0px; position: relative; margin: 50px 45px 30px; }
              .quotation-mark-left { margin-right: 30px; }
              .quotation-mark-right { & { margin-left: 30px; position: relative; } 
                img { position: absolute; bottom: -5px; }
              }
              .no-wrap { white-space: nowrap; }
            }
            .quotation-mark {
              & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; top: 50px; left: -3%; }
              img { height: 25px; width: auto; }
            }
            .bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -57px; left: 6%;; }
            .designation { 
              & { text-align: center; color: #ED2028; font-family: $primaryfont; font-size: 32px; line-height: 38px; font-weight: 700; margin-bottom: 50px; }
              .designation-inner { color: #4A4A4A; font-size: 24px; font-weight: 400; margin: 0; }
            }
          }
        }

        .unlock-growth {
          & { margin: 20px auto; text-align: center; }
          h3 { font-family: $primaryfont; font-size: 36px; font-weight: 700; line-height: 43.2px; text-align: center; color: #FFFFFF; }
          .height30 { height: 30px; }
          .unlock-reasons {
            & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
            .reason { 
              & { flex: 0 1 33%; padding: 0 10px; max-width: 26ch; margin: 0 auto; text-align: center; font-weight: 400; font-size: 21px; line-height: 30px; color: #FFFFFF; font-family: $fontBarlow;  }
              span { font-weight: 700; font-size: 24px; line-height: 49px; }
              img { height: 75px; }
            }
          }
          .cta-btn {
            & { display: inline-block; margin: 20px auto 0; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 200px; }
            // &:hover { border-radius: 25px; background-color: #435B63; transition: 0.3s; color: #fff; }
            a { display: block; text-decoration: none; color: #fff; max-width: 200px; }
            button { width: 100%; max-width: 100%; display:  inline-block;  background-color: #ED2028; padding: 5px 10px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
            button:hover { border-radius: 5px; background-color: #100F2E; transition: 0.3s; color: #fff; }
          }

        }

        .cta-btn {
          & { display: block; margin: 20px auto 0; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 200px; }
          // &:hover { border-radius: 25px; background-color: #435B63; transition: 0.3s; color: #fff; }
          a { display: block; text-decoration: none; color: #fff; max-width: 200px; }
          button { width: 100%; max-width: 100%; display:  inline-block;  background-color: #ED2028; padding: 5px 10px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
          button:hover { border-radius: 5px; background-color: #100F2E; transition: 0.3s; color: #fff; }
        }
      }

  }
  .sample-dashboard {
      .dashboard-heading { 
        & { position: relative; } 
        .line { width: 96%; margin:auto; height: 3px; background-color: #FFFFFF; margin: 80px auto 80px; }
        h1 { color: #FFFFFF; background-color: #464350; text-align: center; font-family: $primaryfont; font-size: 36px; font-weight: 700; padding: 0 30px; position: absolute; top: -40px; left: 50%; transform: translateX(-50%); white-space: nowrap; }
      }
      .scroll-to-see {
        & { display: flex; flex-direction: column; align-items: center; color: #FFFFFF; font-family: $primaryfont;  font-size: 24px;  font-weight: 600; line-height: 28.13px; text-align: center; }
        img { max-height: 30px; }
      }
      .individual-group {
        & { max-width: 400px; width: 376px; margin: 30px auto 45px; display: flex; height: 49px; border-radius: 5px; background-color: #ffffff; }
        button { cursor: pointer; flex: 0 1 50%; border: 0px; background: white; color: #1F2A44; font-family: $primaryfont; font-size: 18px; font-weight: 600; padding: 10px 0; text-align: center; border: 2px solid #FFF; }
        .left { border-top-left-radius: 1px; border-bottom-left-radius: 1px; font-weight: 400; transition: all  ease-in-out 0.2s;border-radius: 5px 0px 0px 5px; }
        .left:hover { /* background-color: #435B63; color: #ffffff; */ transition: ease-in-out 0.2s; border-color: #0E435C; }
        .right { border-top-right-radius: 1px; border-bottom-right-radius: 1px; font-weight: 400; transition: all  ease-in-out 0.2s;background-color: #FFFFFF; border-radius: 0px 5px 5px 0px; }
        .right:hover { /* background-color: #435B63; color: #ffffff; */ transition: ease-in-out 0.2s; border-color: #0E435C; }
        .active { background-color: #FC2B38; color: #ffffff; font-weight: 700; border-color: #FC2B38; border-radius: 5px;}
      }
    
    .results-dashboard-container {
      & { width: 95%; max-width: 1190px; box-sizing: border-box; border-radius: 10px; margin: 30px auto; }
      img { width: 100%; height: auto; border-radius: 10px;}
    }
  }

  .container-start {
    & { height: auto; min-height: 500px; width: 95%; box-sizing: border-box; background: #ffffff; display: flex; justify-content: center; margin: 16px auto 80px; max-width: 1254px; border-radius: 35px; z-index: 1;  }
    .video { width: 100%; box-sizing: border-box; height: auto; border-radius: 10px 10px 0 0; }
    .image { width: 50%; }
    .thumbnail { height: 100%; width: 100%; object-fit: cover; overflow: hidden; background-color: #E5E5E5; border-radius: 35px 0px 0px 35px; }
    .intro {
      & { box-sizing: border-box; background-color: #fff; width: 50%; padding: 30px 3%; display: flex; flex-direction: column; justify-content: center; transition: all 0.25s; border-radius: 0px 35px 35px 0px; }
      h1 { color: #1E1E1E; font-family: $primaryfont; font-size: 36px; font-style: normal; font-weight: 700; line-height: 49px; margin: 0px; }
      p { color: #000000; font-weight: 400; font-size: 21px; width: 100%; line-height: 30px; max-width: 35ch; margin:50px 0; }
      .completion {
        .cta { width: 100%; max-width: 200px; background: #ED2028; border-radius: 6px; color: #fff; text-decoration: none; text-align: center; font-family: $primaryfont; font-size: 21px; line-height: 1; font-weight: 700; letter-spacing: 1px; text-transform: uppercase; padding: 15px 20px; transition: 0.3s }
        .cta:hover { background: #100F2E; transition: 0.3s; color: #fff; }
      }
    }
  }

  .footer-container {
    & { background: rgba(217, 217, 217, 1);  padding: 45px 5% ; box-sizing: border-box; }
    .contents { max-width: 1280px; width: 90%; margin: 0px auto; display: flex; align-items: center; justify-content: space-between; }
    .logo-left { width: 100%; max-width: 72px;  align-self: flex-start; }
    img { max-width: 72px; width: 100%; height: auto; }
    p { font-family: $primaryfont; font-size: 18px; font-weight: 500; line-height: 21px; color: rgba(0, 0, 0, 1); margin: 0px auto; text-align: center; }
    a.privacy-policy { color: #000000; }

  }

  .slider{ 
    & { width: 100%;  position: relative; margin: 20px 0;  overflow: hidden; }
    .wrapper {  transition: .5s all linear; will-change: transform; }
    .arrows { 
      & {  position: absolute; top: 0%; background: none; height: 60px; border: 0; cursor: pointer; transition: ease .3s all; outline: none; }
        img { height: 60px; width: 60px;}
          &.prev {
          & { left: 10%; }
          &:hover { opacity: .7; }
        }
        &.next {
          right: 10%;
          &:hover {  opacity: .7;}
        }
    }
  
    .dots-container{
      & {  height: auto; margin: 0;  padding: 0;  position: absolute;  width: auto;  text-align: center;  left: 50%;  bottom: 0px;  transform: translateX(-50%);  z-index: 10;  list-style-type: none; display: flex; }
        li {
          & { display: inline-block; padding: 5px; }
            &.active {
            button {
              .slider-dots { background-color: #FFFFFF; }
            }
          }
          button { 
            & {  background-color: transparent; border: none;}
            .slider-dots { width: 36px; height: 3px; background-color: #E2E2E266; border-radius: 3px; }
            &:hover{ text-decoration: none; opacity: .7; cursor: pointer; }
          }
        }
    }
    .each-slide { width: 100vw; float: left; padding: 10px 0px 20px;  text-align: center;  background-size: cover;  background-position: center center;  background-color: transparent; }
  }
}


@media only screen and (max-width: 830px) {

  .congregation.landing-page-container {
    // keep styles inside this
    .header-container-wrapper {
      & { padding: 0px  1.3% 0px;}
      .header-container img {
        width: 100%; max-width: 98px;
      }
    }
    .content-container {
      .top-section {
        & { background-image: url(../../../Assets/Images/LandingPage/mob-bg-congregational.png); margin: auto; padding-bottom: 40px; background-position: center; }
        .contents{ 
          & { max-width: 90%; padding: 0px; margin-top: 30px; }
          h1 { font-size: 27px; line-height: 32px; }
          p { font-size: 18px; line-height: 21px; margin: 31px 0px; max-width: 30ch; }
          ol { font-size: 17px; line-height: 39px; padding-left: 18px; }
          .completion {
            & { flex-direction: column; gap: 20px; margin-top: 50px; }
            .cta { width: 90%; padding:15px 20px; }
          }
        }
        .para-container{
          & { width: 89%; margin: 10px auto; }
          p.para {font-size: 14px; line-height: 23px; text-align: center; }

        }

          
      }
      .middle-section {
        & { }
        h3 { font-size: 21px; line-height: 25px; }
        .custom-slider img {
          width: 90%; height: auto;
        }
        .slider .arrows { display: none;}
        .custom-slider .cta { width: 90%; max-width: 200px; }

        .testimonial-container {
          // & { padding: 15px 35px 50px; }
          .testimonial {
            p { font-size: 21px !important; line-height: 43px;  }
            .quotation-mark { top: 25px; left: -7%; }
            .bottom { top: -14px; left: 9%; }
            .designation .designation-inne { font-size: 21px; }
          }
        }

        .unlock-growth {
          h3 { font-size: 21px; line-height: 28px; }
          .unlock-reasons  {
            & { padding: 0; display: flex; flex-direction: column; gap: 30px; }
            .reason { margin: 10px auto; }
          }
          .cta-btn  { max-width: 80%; }
        }

        .sample-dashboard {
          .scroll-to-see {
            h2 { font-size: 21px; max-width: 30ch; }
          }
          .dashboard-heading h1 { font-size: 16px; top: -17px; }
  
        }

      }

    }
    .container-start {
      & { height: auto; flex-direction: column; padding: 0 0 30px; }
      .intro {
        & { width: auto; padding: 20px 15px; margin: 0 auto; text-align: center; border-radius: 0px 0px 35px 35px; }
        h1 { font-size: 24px; line-height: 30px; margin: 0px auto; max-width: 25ch; text-align: center; }
      }
      .image { width: 100%; height: auto; }
      .thumbnail { border-radius: 35px 35px 0px 0px; }
      .completion { margin: 0px auto; width: 100%; }
    }
    .footer-container {
      & { flex-direction: column; gap: 10px; padding: 10px 10px 5px; }
      .contents { flex-direction: column; align-items: center; justify-content: center; gap: 10px; }
      .logo-left { width: 56px; height: auto; align-self: center; }
      p { color: #000000; font-size: 14px; font-weight: 500; }
    }
    .content-container .middle-section .testimonial-container .testimonial .designation { font-size: 24px; }
  }

}


@media only screen and (max-width: 550px) {
  
  .congregation.landing-page-container {
    // keep styles inside this
    .individual-group { display: none; }
    .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
    .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
    .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
    .toggle-individual-group .active { font-weight: 700; color: #ED1B24; color: #ffffff; }
    
    .content-container .middle-section .testimonial-container { width: 95%; }
    .content-container .middle-section .testimonial-container .testimonial p { margin: 50px 30px 30px; }
    .content-container .middle-section .testimonial-container .testimonial p .quotation-mark-left { margin-right: 15px; }
    .content-container .middle-section .testimonial-container .testimonial p .quotation-mark-right { margin-left: 15px; }
  }

}

@media only screen and (max-width: 831px) {
  .congregation.landing-page-container {
    .individual-group { display: none; }
    .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #ffffff; font-size: 21px; }
    .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
    .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
    .toggle-individual-group .active { font-weight: 700; color: #ffffff; }
    .toggle-individual-group .switch-container {
      .MuiSwitch-root { width: 68px; }
      .MuiButtonBase-root.MuiSwitch-switchBase, .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked, .MuiButtonBase-root.MuiSwitch-switchBase:hover, .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked:hover { border: 3px solid #ED1B24; color: #ED1B24; padding: 3px; background-color: #ffffff; top: 8px; }
      .MuiButtonBase-root.MuiSwitch-switchBase, .MuiButtonBase-root.MuiSwitch-switchBase:hover { left: 10px; }
      .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked, .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked:hover { left: 18px; }
      .MuiSwitch-thumb { box-shadow: none !important; width: 10px !important; height: 10px !important; }
      .MuiSwitch-track { background-color: #58555f !important; opacity: 1 !important; width: 45px; min-width: 45px; height: 14px; }
    }
  }
}


