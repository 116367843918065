
  .call-container {
    & { margin: 50px auto 15px; padding: 30px 30px 50px; font-family: $primaryfont; font-size: 21px; line-height: 32px; color: #435B63; background-color: #5A5A5A; text-align: center; width: 96%; max-width: 1100px; box-sizing: border-box; border-radius: 30px; }
    p { padding-top: 20px; font-weight: 400; font-size: 24px; line-height: 36px; color: #ffffff; margin: 15px auto; width: 60ch; }
    p { max-width: 45ch; width: auto; margin: 1rem auto; }
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 26ch; margin: 0 auto; }
        span { color: #435B63; font-weight: 700; }
        img { height: 75px; }
      }
    }
    .cta-btn {
      & { position: relative; padding-top: 30px; display:flex; align-items: center;gap: 10px; margin: 20px auto 0; color: #fff; border-radius: 5px; width: 100%; max-width: 280px; }
      // &:hover { border-radius: 25px; background-color: #435B63; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: max-content; max-width: 100%; display:  inline-block;  background-color: #FF9045; padding: 5px 25px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 600; height: 50px; border-radius: 25px; }
      button:hover { border-radius: 25px; background-color: #fdbf04; transition: 0.3s; color: #fff; }
    }

    .tooltipParent{display: flex;align-items: center; cursor: help; }
    .request-tooltip { display: none; transform: translateX(-50%);padding: 5px; background: rgba(0, 0, 0, 0.5); color: #fff; font-size: 14px; line-height: 16px; max-width: 280px;position: absolute;left: -55%; z-index: 33;}
   .tooltipParent:hover .request-tooltip {   display: flex; }


  }

    @media only screen and (max-width: 650px) {

      .request-tooltip{left: 30% !important; width: 280px;bottom: -60%; }


    }
