@import '../../../Assets/Styles/Variables.scss';

$fontBarlow: 'Barlow', sans-serif;

.home-page-container {
  & { background-color: rgba(242, 242, 242, 1); font-family: $fontBarlow; }
  .header-container-wrapper {
    // & { background: url(../../../Assets/Images/LandingPage/bg.png); color: #FFFFFF; background-size: cover; background-repeat: no-repeat; height: 587px;  }
    & { background: rgba(67, 91, 99, 1); color: #FFFFFF; background-size: cover; background-repeat: no-repeat; height: max-content; padding-bottom: 70px; }

    .header-container {
      & { color: #FFFFFF;  margin: 0 auto; width: 90%; max-width: 1270px; height: min-content; display: flex; align-items: center; justify-content: space-between; padding: 0 2% 0px; box-sizing: border-box; }
      .logo-left { width: 100%; max-width: 210px;  align-self: flex-start; }
      img { max-width: 210px; width: 100%; height: auto; }
    }
    h1 { font-size: 48px;  font-family: $primaryfont; font-weight: 700; line-height: 57.6px; text-align: center; margin: 0 auto; padding:0px 15px 15px; }
    // h2 { font-size: 36px; line-height: 48px; text-align: center; margin: 0 auto; padding: 10px; }  
    p { font-size: 24px;  font-family: $primaryfont; font-weight: 400; text-align: center; margin: 0 auto;  }
  }

  .content-container {
    & { padding: 70px 5px 90px;  width: 90%; max-width: 1280px; min-height: 400px; border-radius: 22px; margin: 0px auto; }
    .sub-container {
      & { display: flex; flex-wrap: wrap; gap: 35px;  justify-content: center; }
      .card {
        & { width: 100%; max-width: 620px; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); border-radius: 10px; background: rgba(255, 255, 255, 1); }
        .bg-image { min-width: 200px; width: 100%; background-position: center; }
        .contents { display: flex; height: 100%; flex-direction: column; align-items: end; justify-content: end; margin-right: 25px; }
        .para { color: #FFFFFF; font-family: $primaryfont; font-size: 30px;  font-weight: 700; line-height: 36px; text-align: center; max-width: 21ch; }
        .cta-btn { margin-bottom: 5px; color: #FFFFFF; background-color: #F73B3B; border-radius: 5px; font-family: $primaryfont; font-size: 21px; font-weight: 700; line-height: 25.2px; text-align: center; min-width: 200px; padding: 10px 20px; }
        .time-estimate {
          & { display: flex; align-items: center; gap: 5px; margin-bottom: 10px; background: #FFFFFFD9; border-radius: 30px; padding: 4px 10px;  }
          .img-container{
            img { width: 18px; }
          }
          span { font-family: $primaryfont; color: #282828; font-size: 18px; font-weight: 500; line-height: 21px; text-align: left; }
        }
        .sub-content-container {
          & { padding: 20px; }
          .title { font-family: $primaryfont; margin: 0px; font-size: 30px; font-weight: 700; line-height:36px;  text-align: left; color: rgba(87, 87, 87, 1); }
          .description { color: rgba(136, 136, 136, 1); font-family: $primaryfont; font-size: 24px;  font-weight: 400;  line-height: 36px; text-align: left; margin: 15px 0px 10px; }
          .try-now {
            & { color: rgba(247, 59, 59, 1); font-size: 24px; font-weight: 700; font-family: $primaryfont; line-height: 24px; text-decoration: none; display: flex; align-items: center; gap: 10px; cursor: pointer; }
            &:hover { text-decoration: underline; }
            img { width: 49px; }
          }
        }
      }

    }
  }

  .footer-container {
    & { background: rgba(217, 217, 217, 1);  padding: 45px 5% ; box-sizing: border-box; }
    .contents { max-width: 1280px; width: 90%; margin: 0px auto; display: flex; align-items: center; justify-content: space-between; }
    .logo-left { width: 100%; max-width: 72px;  align-self: flex-start; }
    .logo-right { width: 100%; max-width: 72px;  align-self: flex-start; }
    img { max-width: 72px; width: 100%; height: auto; }
    p { font-family: $primaryfont; font-size: 18px; font-weight: 500; line-height: 21px; color: rgba(0, 0, 0, 1); margin: 0px auto; text-align: center; }
    a.privacy-policy { color: #000000; }

  }

}

footer {
  display: none;
}


@media only screen and (max-width: 830px) {

  .home-page-container {
    .header-container-wrapper {
      & { padding-bottom: 30px; }
      h1 { font-size: 30px; line-height: 36px; padding: 0 15px; }
      p { font-size: 18px; padding: 10px; }
    }
    .content-container .sub-container .card .sub-content-container {
      .title { font-size: 24px; }
      .description { font-size: 18px; line-height: normal; }
      .try-now { 
        & { font-size: 18px; line-height: 21px; font-weight: 700; }
        img { width: 30px; }
      }
    }

    .header-container-wrapper .header-container { max-width: 100%; padding: 0px 10px 15px; }
    .header-container-wrapper .header-container .logo-left.mobile { max-width: 100%; padding: 0; }
    .header-container-wrapper .header-container .logo-left.mobile img { max-width: 45px; }
  }
  .home-page-container .content-container { padding: 20px 0px; }
  .home-page-container .footer-container {
    & { flex-direction: column; padding: 10px 5% 5px; }
    p { font-size: 14px; }
    img { width: 56px; max-width: 56px; }

  }
  .home-page-container .footer-container .logo-left.mobile { max-width: 100%; text-align: center; }
  .home-page-container .footer-container .logo-right { max-width: 100%; text-align: center; }
  .home-page-container .footer-container .logo-left.mobile img { width: 100%; max-width: 45px; padding: 10px 15px; }
  .home-page-container .footer-container .contents { flex-direction: column; align-items: center; justify-content: center; gap: 10px; }
  .home-page-container .footer-container .logo-left { align-self: center; }
  .home-page-container .header-container-wrapper .header-container img { width: 98px; }
  

}

@media only screen and (max-width: 550px) {

  .home-page-container {
    .header-container-wrapper h1 { font-size: 30px; line-height: 36px; font-weight: 700; }
    .header-container-wrapper p { font-size: 18px; line-height: 24px; padding: 10px 15px; }
    .content-container .sub-container .card .sub-content-container .title { font-size: 21px; line-height: 25px; }
    .content-container .sub-container .card .sub-content-container .description { line-height: 23px; }
  }

}