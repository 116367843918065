
  .invite-container {
    & { margin: 50px auto 15px; padding: 30px 30px 50px; font-family: $fontLato; font-size: 21px; line-height: 32px; color: #343333; background-color: #ffffff; text-align: center; width: 96%; max-width: 800px; box-sizing: border-box; border-radius: 10px; }
    .invite-container-spacer { 
      h3 { font-size: 30px ; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; color: #202945; }
     }
    p { font-weight: 400; font-size: 21px; line-height: 25px; color: #202020; margin: 15px auto; width: 60ch; }
    p { max-width: 72ch; margin: 1rem auto; width: auto; }
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 26ch; margin: 0 auto; }
        span { color: #435B63; font-weight: 700; }
        img { height: 75px; }
      }
    }
    .cta-btn {
      & { display: inline-block; margin: 20px auto 0; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 280px; }
      // &:hover { border-radius: 25px; background-color: #435B63; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: max-content; max-width: 100%; display:  inline-block;  background-color: #F26722; padding: 5px 25px; color: #fff; font-family: $fontLato; font-size: 21px; font-weight: 600; height: 50px; border-radius: 5px; }
      button:hover { border-radius: 5px; background-color: #100F2E; transition: 0.3s; color: #fff; }
    }
    // .cta-btn {
    //   & { width: 100%; max-width: 250px; } 
    //   button { font-size: 21px; font-weight: 700; padding: 11px 10px; letter-spacing: 1px; width: 100%; }
    // }
  }


@media only screen and (max-width: 550px) {
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }
  main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
  main .invite-container p { font-size: 18px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
}