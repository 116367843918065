  .progress-bar {
    & { display: flex; justify-content: space-between; margin: 0 auto; margin-top: 10px; width: 100%; }
    .bar-section { 
      .progress-percentage { color: #100F2E; text-align: right; font-weight: 700; font-size: 21px; margin: 1rem 0 0.5rem 1rem; transition: all 0.4s linear; }
      .progress-grid { 
        display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
        .grid-square { width: 10px; height: 10px; margin: 2px; } 
      }
      .bar { height: 6px; margin: 0 5px 10px; }
      .section-title { width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 21px; line-height: 25px; }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #e5e5e5; height: 6px; border-radius: 3px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #FC2B38; }
    .sections { & {}
      .section-name { font-size: 18px; margin: 15px auto 5px; }
    }
  }