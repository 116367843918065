.testimonial-container {
  // & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
  & { box-sizing: border-box; width: 90%; max-width: 960px; margin: 45px auto 0; padding: 30px 45px 30px; font-family: $primaryfont; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; background-color: #00000066; border-radius: 20px; }
  
  .testimonial { 
    & { display: flex; flex-direction: column; position: relative; color: #ffffff; font-family: $primaryfont; font-size: 24px; line-height: 45px; text-align: center;  }
    .testi { max-width: 100%; box-sizing: border-box; text-align: center; color: #FFFFFF; font-family: $primaryfont; font-size: 19px; font-weight: 400; line-height: 1.2em; }
    .avatar {
      img { border-radius: 50%; padding: 0px; width: auto; height: 109px; }
    }

    p {
      & { font-family: $fontLato; font-style: normal; font-weight: 400; font-size: 23px; line-height: 44px; max-width: 54ch; }
      span { font-weight: 700; font-style: italic; }
    }
    .quotation-mark { & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; top: 10px; left: -5%; }
      img { height: 25px; width: auto; }
      // .quotation-block { position: absolute; right: 15px; top: -15px; }
    }
    .bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -15px; left: 2%; }
    .designation { 
      & { text-align: center; color: #fff;font-family: $fontLato; font-size: 32px; line-height: 38px; font-weight: 700; }
      span { font-weight: 300; font-style: normal; }
      .designation-inner { font-size: 18px; line-height: 22px; margin: 15px auto; font-weight: 600; }
    }
  }
}




@media only screen and (max-width: 1100px) {

  main .testimonial-container .testimonial .testi br { content: ""; }
  main .testimonial-container .testimonial .testi br:after { content: " "; }
}
@media only screen and (max-width: 830px) {
  .testimonial-container .testimonial { font-size: 21px; flex-direction: column; }
}
@media only screen and (max-width: 650px) {
  .testimonial-container .testimonial .quotation-mark img { height: 18px; width: auto; }
  .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    p { font-size: 19px; line-height: 30px; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 18px; }
    .designation { color: #FFF; 
      span { color: #fff; font-size: 18px; }
    }
  }
}

@media only screen and (max-width: 830px) {
  .testimonial-container { padding: 15px 30px 30px; width: 95%; }
}

@media only screen and (max-width: 550px) {
  .testimonial-container { flex-wrap: wrap; padding: 15px 10px 30px; width: 95%; }
  .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  .testimonial-container .testimonial .designation { font-size: 26px; }
  .testimonial-container .testimonial .designation span { padding-top: 6px; }
  .testimonial-container .testimonial { font-size: 18px; width: 90%; margin: 0px auto 0; text-align: center; }
}