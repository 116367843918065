.start-explainer-container {
  & { box-sizing: border-box; background: #ffffff; max-width: 830px; margin: 48px auto; border-radius: 10px; padding: 48px; }
  * { box-sizing: border-box; padding: 0; margin: 0; }
  .start-explainer {
    & { height: 100%; width: 100%; }
    .heading {
      & { color: #100F2E; font-family: $fontLato; font-size: 24px; font-weight: 700; line-height: 36px; text-align: center; max-width: 40ch; margin: 15px auto; }
      .text-orange {
        & { color: #F26722; }
      }
    }
    ul {
      & { list-style: none; padding: 0; margin: 0 auto; max-width: 500px; margin-top: 40px;  }
      li {
        & { display: flex; align-items: center; margin: 20px 0; gap: 15px; color: #343333; }
        h3 { font-family: $fontLato; font-size: 15px; font-weight: 700; line-height: 18px; letter-spacing: 1px; }
        p { font-family: $fontLato; font-size: 15px; font-weight: 400; line-height: 18px; }
      }
    }
    .completion {
      & { display: flex; width: 100%; align-items: center; justify-content: space-between; margin-top: 40px; }
      .cta { display: block; background-color: #F26722; border-radius: 6px; color: #fff; text-decoration: none; width: 100%; max-width: 200px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 7px 12px; margin-left: 0; transition: 0.3s; letter-spacing: 1px; line-height: 25.2px; }
      .cta:hover { background-color: #100F2E; transition: 0.3s; color: #fff; }
      .cta.left.prev-btn { width: 50px; height: 50px; min-width: 50px; max-width: 50px; padding: 0; border-radius: 50%; margin: 0 !important; }
      .right { width: 50px; max-width: 50px; }
    }
  }
}

@media only screen and (max-width: 650px) {
  .start-explainer-container { max-width: 95%; padding: 30px 20px; margin: 48px auto 0px; } 
  .start-explainer-container .start-explainer .heading { line-height: 26px; }
}

@media only screen and (max-width: 650px) {
  .start-explainer-container .start-explainer .heading { max-width: 22ch; }
}

