  .chart-container { & { width: 96%; max-width: 1110px; border-radius: 10px; background-color: #FFFFFF; margin: 25px auto 5px; }
    .chart-heading { & { font-family: $primaryfont; margin: 25px auto 5px; justify-content: center; text-align: center; }
      h1 { padding: 30px 15px 0; font-weight: 600; font-size: 24px; line-height: 31px; color: #343333; margin: 15px auto 5px; max-width: 57ch; }
      h1.exception { font-weight: 600; }
      p { font-weight: 600; font-size: 24px; line-height: 31px; color: #334433; margin-top: 0; padding: 0 10px; }
      span { color: #EC1C24; font-weight: 700; }
    }

    // .chart-data{margin: 5px auto;}
    .step-buttons { & {width: 100%; display: flex;justify-content:space-between;margin: 5px auto; align-items: center; max-width: 85%; margin: 15px auto; padding: 30px 15px; }
    .cta-prev { padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 400; font-family: $primaryfont; }
    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
    .cta {border: none; padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 600; font-family: $primaryfont;
    }      
    .next-btn { border-bottom :1px solid #EC1C24; display: inline-block; }
    // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
    // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
    // .nextbtn:hover { background-color: $color2; }
    // .nextbtn.Mui-disabled { background: #C1C1C1; }
    // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }

  @media only screen and (max-width: 830px) {

  .chart-container { & {}
    .chart-heading{ & { font-family: $primaryfont; margin: 25px auto 5px; justify-content: center; text-align: center;}
      h1 { padding-top: 25px; font-weight: 600; font-size: 21px; line-height: 31px; color: #343333; }
      p { font-weight: 600; font-size: 21px; line-height: 31px; color: #343333; padding: 0 10px; }
      span { color:#EC1C24; font-weight: 700; }
    }

  // .chart-data{margin: 5px auto;}

    .step-buttons { & { width: 100%; display: flex; justify-content:space-between; margin: 25px auto; align-items: center; max-width: 85%; padding: 10px 25px 15px; }
    .cta-prev { padding: 11px 25px; color: #182036; font-size: 16px; font-weight: 400; font-family: $primaryfont; }

    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }

    .cta {
      & { max-height: 50px; background-color: #ED1824; font-weight: 700; font-size: 21px;line-height: 25.2px; color: #FFFFFF; padding: 11px 18px; }
      &:hover { background: #435B63;transition: 0.3s; color: #fff; }
      }
      .next-btn{border-bottom: none;}

    // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
    // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
    // .nextbtn:hover { background-color: $color2; }
    // .nextbtn.Mui-disabled { background: #C1C1C1; }
    // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }
}

@media only screen and (max-width: 650px) {

  .chart-container{ & { margin: 15px auto; width: 95%; }
  .chart-heading h1 { font-weight: 600; }
  .chart-heading h1.exception { font-weight: 600;  }
// .chart-data{margin: 5px auto;}

.step-buttons { & {padding: 1px 25px 15px; margin: 1px auto;}
.cta-prev { padding: 5px 25px;margin-bottom: 15px; }
.cta{margin-bottom: 15px; }
// .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }



// .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
// .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
// .nextbtn:hover { background-color: $color2; }
// .nextbtn.Mui-disabled { background: #C1C1C1; }
// .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
}
}
}

@media only screen and (max-width: 550px) {

  .chart-container .chart-heading p { max-width: 36ch; margin: 0px auto 5px; }
}