 .firstform-container { & { margin: 26px auto 80px;  border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336); width: 96%; max-width: 575px; min-height: 520px; background-color: #FFFFFF; }
  .firstform{padding-top: 48px;margin: 1px auto;text-align: center; 
    h1 { font-family: $primaryfont; font-weight:bold; font-size: 24px; line-height: 34px; color: #343333; padding: 0 15px; }
  }
  .form { & { text-align: center;width: max-content; margin: 1px auto; }
    .form-detail { & { margin: 1px auto;text-align: center; }
      .input-title { text-align: start; font-size: 16px;font-weight: 200; line-height: 19.2px; color: #3B3A3A; margin-bottom: 4px; }
      .form-details-text { width: 216px; height: 45px;font-family: $primaryfont; font-weight: 300; font-size: 18px; line-height: 21.6px;}
      .form-details-text .MuiOutlinedInput-input { font-family: $primaryfont; font-weight: 400; font-size: 18px; color: #343333; }
      .Mui-focused fieldset{ border: 2px solid #ED1B24; }
      // .MuiInputBase-root::placeholder { font-family: $primaryfont; color: #34333380; opacity: 1 !important; }
      .error-text { color: red; font-weight: 400; font-size: 13px;padding: 5px 3px;margin-top: 10px; max-width: 216px; text-align: left; }
      input::placeholder { font-weight: 400; color: #919191; opacity: 1 !important; } 
    }
    .attendance{ padding-top: 5px; max-width: 216px;}
    .attendance .MuiOutlinedInput-input::placeholder { font-family: $primaryfont; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
    .zip{ padding-top: 10px; margin-top: 15px;}
    .zip .MuiOutlinedInput-input::placeholder { font-family: $primaryfont; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
  }
  .cta{ & { text-align: center; padding-top:10px;}
    .cta-btn {
      & { margin:45px auto 5px auto; padding: 25px 5px; text-decoration: none; width: 216px; height: 50px; background: #ED1B24; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
      &:hover { background: #435B63; transition: 0.3s; color: #fff; }
    }
  }

  .firstform-footer { & { width: 95%; margin: 1px auto; padding-bottom:5px; padding-top: 10px; }
    .firstform-footer-text{ max-width: 42ch; text-align: center; margin: 15px auto; font-weight: 400; font-size: 18px; line-height: 24px; padding-bottom: 5px; }
  }
}

@media only screen and (max-width: 550px) {
  .firstform-container { & { margin: 26px auto 80px; width: 95%; min-height: 525px; }
    .firstform { & { padding-top: 20px; margin: 1px auto; }
      h1 { font-weight:700; font-size: 21px; line-height: 38px;padding: 5px; }
    }
    .cta{
      .cta-btn {
        & {margin:25px auto 5px auto; padding: 25px 5px; text-decoration: none; width: 216px; height: 50px; background: #ED1B24; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
        &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
    .firstform-footer { & { max-width: 40ch; text-align: center; padding: 5px 5px 10px 5px; }
      .firstform-footer-text { font-weight: 400; font-size: 15px; line-height: 24px; margin-top: 40px; }
    }
  }

}