
$color3: #343333; 
   .pre-additional-questions-details {
    & { min-height: 630px; max-width: 1110px; width: 95%; background: #fff; border-radius: 30px; }
    h1 { width: auto;font-weight: 700;font-size: 36px; line-height: 34px; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 36px; color: $color3; padding: 32px 15px 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; max-width: 35ch; line-height: 34px; color: $color3; padding: 0 15px; margin: 15px auto; }

    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 43px auto 0px auto; width: 65%; }
      .form-detail { 
        * { box-sizing: border-box; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 400; line-height: 19px;color: #3B3A3A; }
        .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; font-weight: 400; }
        .MuiInput-input::placeholder { font-weight: 400; color: #919191; opacity: 1 !important; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        // .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text { background-color: #F4F4F4; margin-top: 5px; max-width: 100%; border-radius: 20px; border:  1px solid #ccc;}
        .form-details-text .MuiInput-root { padding: 10px 15px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid #61CE70; border-radius: 20px; }
        
        
        .form-details-text.react-select div:nth-child(3) { border: 1px solid #ccc; border-radius: 20px; }
        .form-details-text.react-select div:nth-child(6) { border: 1px solid #ccc; border-radius: 20px; }
        .form-details-text .react-select__control--is-focused { border: 1px solid #61CE70; box-shadow: 0 0 0 1px #61CE70; } 
        .form-details-text.react-select .react-select__value-container { padding: 10px 15px; }
        .form-details-text.react-select .react-select__indicator-separator { display: none; }
        .form-details-text .react-select__single-value { font-weight: 400; }
        .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; color: #919191; font-weight: 400; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: $primaryfont; font-weight: 600; margin-top: 10px; }
          // div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        // .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        .error-text { position: initial; color: red; font-weight: 400; font-size: 13px; }
        .required-field { color: #c02b0a; font-size: 22px; padding-left: 4px; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
    }

    .details-buttons { & { display: grid; grid-template-columns: 0.5fr 1fr 0.5fr; max-width: 80%; margin: 0 auto; padding-top: 3rem; padding-bottom: 3rem; } 
      .back-btn { & { align-self: center; } button { color: #444444; padding: 10px 15px; }
        button { font-family: $primaryfont; font-size: 18px; }
      }
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; }
        a { text-decoration: none; }
        button.Mui-disabled { background-color: #FF9045; color: #FFFFFF; border-radius: 30px; width: 300px; }
        button { font-family: $primaryfont; display: block; background-color: #FF9045; border-radius: 30px; color: #fff; text-decoration: none; width: 250px; height: 50px; text-align: center; font-size: 21px; font-weight: 700; line-height: 25px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s; width: 300px; }
        // button:hover { background: #435B63; transition: 0.3s; color: #fff;}
      .button-progress { margin: 4px 0 -2px; }
    }
    .cta-btn-active {
      & { margin: 0 auto; text-decoration: none; align-self: center; }
      a { text-decoration: none; }
      button.Mui-disabled { background-color: #D8D8D8; color: #FFFFFF; }
      button { display: block; background-color: #ED2028; border-radius: 5px; color: #fff; text-decoration: none; width: 250px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; line-height: 25px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s}
      button:hover { background: #435B63; transition: 0.3s; color: #fff;}
    .button-progress { margin: 4px 0 -2px; }
  }
    }
  }




@media only screen and (max-width: 650px) {

  main .details, main .pre-additional-questions-details {
    .form { & { width: 90%; } 
    .form-detail { & { flex: 0 1 100%; max-width: 100%; }
      .input-title { max-width: 100%; }
      .error-text { margin: 0 auto -10px; }
    }
    }
  }
}