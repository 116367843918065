
 .results, .dasboard {
    // .urgency:last-child { padding-bottom: 75px; }
    .urgency {
      & { margin: 0 auto; padding: 1rem 0; width: 95%; max-width: 777px; }
      h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: start; margin: 15px auto 15px; }
      .urgency-slider { 
        & { position: relative; background-color: rgba(115, 183, 111, 0.29); margin: 0 auto; padding: 115px 9% 80px; border-radius: 10px; }
        h3 { position: absolute; top: 0; color: #858585; font-weight: 400; font-size: 15px; left: 4%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -48%; margin-top:-5px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000;color:#858585;font-size:13px;font-weight:400 ; }
          }
        }
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 9999; margin-left: -11px; margin-top: -9px; text-align: center; font-size: 18px; font-weight: 700; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 31px; top: -44px; margin-left: -2px; background-color: #73B76F; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: 700; font-size: 18px; color: #5E5E5E; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background-color: #73B76F; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color:rgba(115, 183, 111, 0.4223); width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
      }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid rgba(115, 183, 111, 0.4223); height: 2px; }

      .custom-slider .MuiSlider-thumb { background-color: #73B76F; }
      .custom-slider .MuiSlider-thumb:before { background-color: #73B76F; border: 4px solid #73B76F; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider.individual .MuiSlider-thumb:before { background-color: #73B76F; border: 4px solid #73B76F; width: 24px; height: 24px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #D8D8D8; height: 2.5px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #D8D8D8; }
    }
    
}


@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }

}

@media only screen and (max-width: 550px) {

  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard {
    .hurdles-enablers {
      h2 { font-size: 16px; }
      li { font-size: 16px; }
    }
    .urgency {
      h2 { font-size: 16px; }
      .urgency-slider { margin: 0;  }
      .urgency-slider.individual { padding-top: 45px !important; }
      .custom-slider.MuiSlider-root { color: transparent; }
      .custom-slider .MuiSlider-track { border: 0px; }
      .MuiLinearProgress-root.custom-bar {  height: 8px; margin-top: -22px; border-radius: 5px; width: calc(100% + 3px); margin-left: -2px; }
      .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }
    }
  }
}

